var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"border-left"},[(_vm.showIndex)?_c('th',{class:{ ['sticky-column']: _vm.hasWidth && _vm.stickyColumns, [_vm.customIndexClassName]: _vm.customIndexClassName },style:({
      ..._vm.getAdditionalColumnStyles('index'),
      width: `${_vm.additionalColumns.find((col) => col.name == 'index').width}px`,
    }),attrs:{"scope":"col"}}):_vm._e(),_vm._l((_vm.columns),function(column,columnIndex){return _c('th',{key:`${columnIndex}-${column.key}-sub-header`,class:{
      ['sticky-column']: _vm.isColumnIndexSticky(columnIndex),
      ['hidden-column']: column.hidden,
      [column.subHeaderCustomClass]: column.subHeaderCustomClass,
    },style:(_vm.getHeaderStyle(columnIndex)),attrs:{"scope":"col"}},[_vm._t(`sub-header-${column.key}`,null,null,{ columnIndex, column })],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }