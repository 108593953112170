<template>
  <el-input
    v-model="value"
    class="w-25 mb-5"
    :placeholder="placeholder"
    :clearable="true"
    @input="() => emit('onChange', value)"
    @clear="() => emit('onClear')"
  >
    <template #prefix>
      <SearchIcon class="search-icon" />
    </template>
  </el-input>
</template>
<script>
import { ref } from 'vue';

import { SearchIcon } from '@/assets/icons';

export default {
  components: { SearchIcon },
  props: {
    searchValue: { type: String, required: true },
    placeholder: { type: String, default: '' },
  },
  emits: ['onChange', 'onClear'],
  setup(props, { emit }) {
    return {
      value: ref(props.searchValue),
      emit,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-icon {
  margin-top: 12px;
  width: 1rem;
  height: 1rem;
  margin-right: 4px;
}
</style>
