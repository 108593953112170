<template>
  <tr class="border-left">
    <th
      v-if="showIndex"
      scope="col"
      :class="{ ['sticky-column']: hasWidth && stickyColumns, [customIndexClassName]: customIndexClassName }"
      :style="{
        ...getAdditionalColumnStyles('index'),
        width: `${additionalColumns.find((col) => col.name == 'index').width}px`,
      }"
    ></th>
    <th
      v-for="(column, columnIndex) in columns"
      :key="`${columnIndex}-${column.key}-sub-header`"
      :class="{
        ['sticky-column']: isColumnIndexSticky(columnIndex),
        ['hidden-column']: column.hidden,
        [column.subHeaderCustomClass]: column.subHeaderCustomClass,
      }"
      :style="getHeaderStyle(columnIndex)"
      scope="col"
    >
      <slot :name="`sub-header-${column.key}`" v-bind="{ columnIndex, column }"></slot>
    </th>
  </tr>
</template>
<script>
export default {
  props: {
    showIndex: { type: [Boolean, Number], required: true },
    hasWidth: { type: Boolean, required: true },
    stickyColumns: {
      type: [Number, String],
      validator: (value) => Number.isSafeInteger(Number(value)),
      required: true,
    },
    additionalColumns: { type: Array, required: true },
    columns: { type: Array, required: true },
    getAdditionalColumnStyles: { type: Function, required: true },
    isColumnIndexSticky: { type: Function, required: true },
    getHeaderStyle: { type: Function, required: true },
    customIndexClassName: { type: String, default: null },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

table > thead > tr > th,
table > tbody > tr > td {
  background: $white;
  &.hidden-column {
    display: none;
  }
  &.sticky-column {
    position: sticky;
    &:not(td) {
      z-index: 1;
    }
    div.scrollingX > & {
      [dir='rtl'] & {
        box-shadow: -4px 0px 5px rgba(91, 104, 118, 0.09);
      }
      [dir='ltr'] & {
        box-shadow: 4px 0px 5px rgba(91, 104, 118, 0.09);
      }
    }
  }

  &.active-actions {
    background: $surfaces-selected;
  }
}
</style>
