<template>
  <el-dialog
    width="393px"
    visible
    :show-close="false"
    append-to-body
    custom-class="rounded picture-edit-modal"
    @close="close"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h2 class="text-typography-primary">
          {{ $t('pictureEditModal.title') }}
        </h2>
        <Button type="text" class="p-0 text-typography-primary actions-btn" @click="close">
          <CloseIcon width="24" height="24" />
        </Button>
      </div>
    </template>
    <div v-loading="loading" class="py-5 px-7">
      <vue-croppie
        ref="croppieRef"
        :enable-resize="false"
        :viewport="{ width: 200, height: 200, type: 'circle' }"
        :boundary="{ width: 220, height: 220 }"
      >
      </vue-croppie>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="close">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="submit">
          {{ $t('commons.apply') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { CloseIcon } from '@/assets/icons';
import Button from './Button';

export default {
  components: { Button, CloseIcon },
  props: {
    url: { type: String, required: true },
  },
  data() {
    return { loading: false };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.croppieRef.bind({
        url: this.url,
      });
    });
  },
  methods: {
    submit() {
      let options = {
        format: 'png',
        circle: true,
        quality: 1,
        type: 'blob',
      };
      this.$refs.croppieRef.result(options, (file) => {
        this.$emit('pictureUpload', file);
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .picture-edit-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}

.actions-btn {
  cursor: pointer;
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}
</style>
