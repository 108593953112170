<template>
  <div class="selector">
    <div class="chevron" @click="$emit('backward')">
      <ChevronIcon :direction="$direction === 'rtl' ? 'right' : 'left'" />
    </div>
    <div class="month-text">{{ selectedMonthText }}</div>
    <div class="chevron" @click="$emit('forward')">
      <ChevronIcon :direction="$direction === 'rtl' ? 'left' : 'right'" />
    </div>
  </div>
</template>

<script>
import { ChevronIcon } from '@/assets/icons';

export default {
  components: { ChevronIcon },
  props: {
    start: { type: Date, required: true },
    end: { type: Date, required: true },
  },
  computed: {
    selectedMonthText() {
      const startMonth = this.formatMonth(this.start);
      const endMonth = this.formatMonth(this.end);

      if (startMonth === endMonth) {
        return startMonth;
      }
      return [startMonth, endMonth].join(' - ');
    },
  },
  methods: {
    formatMonth(date) {
      return date.toLocaleString(this.$i18n.locale, { month: 'long', year: 'numeric' });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.selector {
  display: grid;
  grid-template-columns: 24px 14vw 24px;
  justify-items: center;

  .chevron {
    svg {
      border-radius: 36px;
    }
    :hover {
      background: $light-gray;
      color: $primary;
      cursor: pointer;
    }
  }
  .month-text {
    line-height: 1.7;
  }
}
</style>
