<template>
  <el-popover v-bind="$attrs" ref="popoverRef" @show="isPopoverVisible = true" @hide="isPopoverVisible = false">
    <TableFilter
      slot="reference"
      :filter-name="filterName"
      :filter-value="filterValue"
      :min-width="minWidth"
      :active="isPopoverVisible"
      :clearable="clearable"
      @on-clear-filter="$emit('on-clear-filter')"
    >
      <slot slot="icon" name="icon"></slot>
    </TableFilter>
    <slot></slot>
  </el-popover>
</template>
<script>
import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
import TableFilter from './TableFilter';

export default {
  components: { TableFilter },
  props: {
    filterName: { type: String, default: null },
    filterValue: { type: String, default: null },
    minWidth: { type: String, default: null },
    clearable: { type: Boolean, default: false },
    shouldClosePopoverOnScroll: { type: Boolean, default: false },
  },
  setup(props) {
    const popoverRef = ref(null);
    const isPopoverVisible = ref(false);

    const closePopover = () => {
      popoverRef.value.doClose();
    };

    onBeforeMount(() => {
      if (props.shouldClosePopoverOnScroll) {
        const div = document.getElementById('router-container');
        div.addEventListener('scroll', handleScroll);
      }
    });

    onBeforeUnmount(() => {
      if (props.shouldClosePopoverOnScroll) {
        const div = document.getElementById('router-container');
        div.removeEventListener('scroll', handleScroll);
      }
    });

    const handleScroll = () => {
      closePopover();
    };

    return {
      popoverRef,
      isPopoverVisible,
      handleScroll,
      closePopover,
    };
  },
};
</script>
<style lang="scss" scoped></style>
